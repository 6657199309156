
const enums = {
  ApiResult: {
    Ok: 200,
    Unauthorized: 201,
    BadRequest: 400,
    AccessDenied: 401,
    NotFound: 404,
    Timeout: 408,
    ValidationError: 422,
    InternalServerError: 500,
    InsufficientStorage: 507
  },

  UserStatus: {
    NewUser: 0,
    Active: 1,
    Deactive: 2
  },

  UserStatusName: {
    0: 'New User',
    1: 'Active',
    2: 'Deactive'
  },

  Duration: {
    Today: 0,
    Yesterday: 1,
    CurrentWeek: 2,
    Last7Days: 3,
    CurrentMonth: 4,
    LastMonth: 5,
    ThreeMonths: 6,
    SixMonths: 7,
    CurrentYear: 8,
    LastYear: 9,
    Between: 10
  },

  SortBy: {
    DESC: 2,
    ASC: 1
  },

  userRole: {
    SuperAdmin: 1,
    CJAdmin: 2,
    User: 3,
    SiteManager: 4
  },

  OrderModes: ['WalkIn', 'EatIn', 'DriveThru', 'Delivery'],

  MealSize: ['0', '1', '2'],

  deviceType: ['Kiosk', 'Mobile', 'Pos'],

  BannerType: {
    1: 'Top',
    2: 'Bottom',
    3: 'Kiosk'
  },

  ComboSize: {
    0: 'Small',
    1: 'Medium',
    2: 'Large'
  },

  ModifiersSet: {
    2: 'Protien',
    3: 'Cheese',
    4: 'Sauce',
    5: 'Packet Sauce',
    6: 'Salad',
    7: 'Bun'
  },

  DeliveryType: {
    2: 'Uber',
    3: 'Doordash'
  },

  OrderStatus: ['PROCESSING', 'PAID', 'PAYMENT FAILED', 'PLACED', 'COOKING', 'FULFILLED', 'CANCELED', 'REFUNDED'],

  SummaryType: {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly'
  },

  OrderSource: ['Kiosk', 'Mobile', 'POS'],

  AdminRole: {
    1: 'Super Admin',
    2: 'CJ Admin',
    3: 'User',
    4: 'Site Manager'
  },

  ComparisonType: {
    '<': '<',
    '<=': '<=',
    '>': '>',
    '>=': '>=',
    '=': '='
  },

  Gender: {
    Male: 'M',
    Female: 'F',
    Others: 'O',
    'Un Specified': 'U'
  },

  PosScreenType: {
    singleDisplay: 1,
    doubleDisplayClient: 2,
    doubleDisplayServer: 3
  },

  KioskNotificationType: {
    ScreenSaver: 'Screen Saver',
    MenuScreen: 'MenuScreen'
  },

  orderSource: {
    kiosk: 'Kiosk',
    pos: 'POS',
    mobile: 'Mobile'
  },
  DisablePosItemType: {
    MenuItemType: 1,
    ModifierItemType: 2,
    ComboType: 3
  },
  SyncButtons: ['Screen Savers', 'Menu Items'],
  screenType: {
    prep: 'Prep',
    pack: 'Pack'
  },
  groupType: {
    suggestive: 30
  },
  menuType: {
    quickCombo: "QuickCombo",
    menuItem: 'MenuItem'
  },
  QuickComboSize: {
    Small: 0,
    Medium: 1,
    Large: 2
  }
};

export default enums;
