import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';

// Global Imports
import 'service/AxiosService';

// Project Imports
import { Box, CircularProgress, Typography } from '@mui/material';
import Snackbar from 'components/@extended/Snackbar';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Routes from 'routes';
import ApiService from 'service/ApiService';
import { setAuthDetail, setTokens } from 'store/reducers/auth';
import ThemeCustomization from 'themes';
import utils from 'utils/utils';

const App = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const [isTokenChecked, setIsTokenChecked] = useState(isLoading);

  useEffect(() => {
    bindInitData(false);
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleMessage = async (event) => {
    let receiveToken = event.data && event.data.refreshToken;
    if (receiveToken) {
      await renewSession(receiveToken);
      bindInitData(true);
    }
  };

  const renewSession = async (refreshToken) => {
    try {
      const { data } = await ApiService.renewSessionAsync({ refreshToken });
      if (data) {
        const token = { session: data.sessionToken, refresh: data.refreshToken };
        utils.setTokensToStorage(token);
        const currentTokenUser = jwtDecode(token.session);
        dispatch(setAuthDetail({ user: currentTokenUser, token }));
      }
    } catch (error) {
      console.debug('generate session token issue : ', error);
    } finally {
      setIsTokenChecked(false);
    }
  };

  const bindInitData = async (isSSO) => {
    const token = utils.getTokensFromStorage();
    if (token) {
      const currentTokenUser = jwtDecode(token.session);
      dispatch(setAuthDetail({ user: currentTokenUser, token }));
      setIsTokenChecked(false);
    } else {
      setIsTokenChecked(false);
      if (isSSO) {
        dispatch(setTokens({ token: null }));
      }
    }
  };

  const AuthLoader = () => (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress size={60} color={'success'} />
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Authenticating, please wait...
      </Typography>
    </Box>
  );

  if (isTokenChecked) {
    return <AuthLoader />;
  }

  return (
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <Routes />
          <Snackbar />
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
