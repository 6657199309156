import { DeleteOutlineOutlined } from '@mui/icons-material';
import { Autocomplete, Grid, IconButton, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import CjDialog from 'components/@extended/Dialog';
import CjReactTable from 'components/@extended/Table/ReactTable';
import { GlobalFilter } from 'components/@extended/Table/ReactTableFilter';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { useEffect, useMemo, useState } from 'react';
import ApiService from 'service/ApiService';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import _ from 'lodash';
import dateHelper from 'utils/dateHelper';

const ManageSuggestiveItem = ({ suggestiveGroups, assignedItems, onDelete }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [globalFilter, setGlobalFilter] = useState();
    const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedGroupItem, setSelectedGroupItem] = useState();
    const [groupItemList, setGroupItemList] = useState([]);
    const [selectedScreenGroup, setSelectedScreenGroup] = useState(suggestiveGroups[0]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let cloneGroupItems = _.cloneDeep(assignedItems)
        if (!_.isEmpty(selectedScreenGroup)) {
            const filteredItems = cloneGroupItems.filter((item) => item.subMenuId === selectedScreenGroup.subMenuId);
            setGroupItemList(filteredItems)
        } else {
            setGroupItemList(cloneGroupItems)
        }

    }, [assignedItems, selectedScreenGroup])


    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Updated by',
                accessor: 'updatedBy',
                Cell: ({ row }) => {
                    const { values, original } = row;
                    return (
                        <Typography variant='subtitle1'>{values.updatedBy || '-'}</Typography>
                    );
                }
            },
            {
                Header: 'Updated Date',
                accessor: 'updatedDate',
                Cell: ({ row }) => {
                    const { values, original } = row;
                    return (
                        <Typography >
                            {original.updatedDate ? dateHelper.getFormatDate(original.updatedDate) : '-'}
                        </Typography>
                    );
                }
            },
            {
                Header: 'Action',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const { original } = row;
                    return (
                        <Stack direction='row' spacing={2}>
                            <Tooltip title='Delete Rule'>
                                <IconButton
                                    color='error'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedGroupItem(original);
                                        setOpenConfirmDialog(true);
                                    }}
                                >
                                    <DeleteOutlineOutlined />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    );
                }
            }
        ],
        [theme]
    );




    const onCloseDialog = () => {
        setSelectedGroupItem(null);
        setOpenConfirmDialog(false);
    };

    const onDeleteGroupItem = async () => {
        setIsLoading(true)
        const { data } = await ApiService.deleteSuggestiveItemAsync(selectedGroupItem.posItemId);
        if (data) {
            setGroupItemList(groupItemList.filter((item) => item.posItemId !== selectedGroupItem.posItemId))
            onDelete(selectedGroupItem)
            dispatch(
                openSnackbar({
                    open: true,
                    message: data ? 'Item deleted successfully.' : '',
                    variant: 'alert',
                    alert: {
                        color: data ? 'success' : 'error'
                    },
                    close: true
                })
            );
        }
        setIsLoading(false)
        onCloseDialog();
    };

    const handleGroupChange = (newValue) => {
        setSelectedScreenGroup(newValue);
    };


    return (
        <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12}>
                <MainCard title={'Assigned Suggestive Items'} content={false}>
                    <ScrollX>
                        <Grid
                            container
                            direction={matchDownSM ? 'column' : 'row'}
                            justifyContent='space-between'
                            alignItems='center'
                            spacing={1}
                            sx={{ py: 3, px: 3 }}
                        >
                            <Grid item xs={12} sm={4} md={4}  >
                                <Stack direction='column' spacing={2}>
                                    <Autocomplete
                                        id='screenGroup'
                                        sx={{ width: '100%' }}
                                        options={suggestiveGroups}
                                        value={selectedScreenGroup}
                                        getOptionLabel={(screenGroup) => screenGroup.name || ''}
                                        onChange={(event, newValue) => handleGroupChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder='Filter by group'
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <GlobalFilter
                                    preGlobalFilteredRows={groupItemList}
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    size='large'
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                        <Stack maxHeight={'68vh'}>
                            <CjReactTable globalFilter={globalFilter} columns={columns} data={groupItemList} />
                        </Stack>
                    </ScrollX>
                    {isOpenConfirmDialog && selectedGroupItem ? (
                        <CjDialog
                            onCancel={onCloseDialog}
                            confirmHandle={onDeleteGroupItem}
                            isDialogOpen={isOpenConfirmDialog}
                            title={`Delete Group`}
                            Content={`Are you sure you want to delete ${selectedGroupItem.name}?`}
                            isLoading={isLoading}
                        ></CjDialog>
                    ) : null}
                </MainCard>
            </Grid>
        </Grid>
    );
};
export default ManageSuggestiveItem;
