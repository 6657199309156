import _ from 'lodash';
import { Grid } from '@mui/material';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import ApiService from 'service/ApiService';
import UnassignedItem from './unassignedItem';
import ManageSuggestiveItem from './manageSuggestiveItem';
import enums from 'utils/enums';

const AddEditSuggestiveItem = () => {
    const [isLoading, setLoading] = useState(true);
    const [suggestiveGroups, setSuggestiveGroups] = useState([]);
    const [assignedItems, setAssignedItems] = useState([]);
    const [unassignedItemsList, setUnassignedItemsList] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const [categories, unAssignedItems, assignedItems] = await Promise.all([
            ApiService.getSubMenuItemsAsync(),
            ApiService.getUnassignedSuggestiveItemAsync(),
            ApiService.getAssignedSuggestiveItemAsync()
        ]);
        let suggestiveGroup = _.filter(categories.data, (item) => item.groupType === enums.groupType.suggestive);
        setSuggestiveGroups(suggestiveGroup);
        setUnassignedItemsList(unAssignedItems.data);
        setAssignedItems(assignedItems.data);
        setLoading(false);
    };


    const handleSave = async () => {
        const [unAssignedItems, assignedItems] = await Promise.all([
            ApiService.getUnassignedSuggestiveItemAsync(),
            ApiService.getAssignedSuggestiveItemAsync()
        ]);

        setUnassignedItemsList(unAssignedItems.data);
        setAssignedItems(assignedItems.data);
    };

    const handleDelete = (value) => {
        setUnassignedItemsList((PrevList) => [{ posItemId: value.posItemId, name: value.name }, ...PrevList])
        fetchData();
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={4}>
                        <UnassignedItem
                            suggestiveGroups={suggestiveGroups}
                            unassignedItemsList={unassignedItemsList}
                            onSave={handleSave}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <ManageSuggestiveItem
                            suggestiveGroups={suggestiveGroups}
                            assignedItems={assignedItems}
                            onDelete={handleDelete}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default AddEditSuggestiveItem;
