import { SearchOutlined } from '@mui/icons-material';
import {
    Autocomplete,
    CardHeader,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    OutlinedInput,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import LoadingButton from 'components/@extended/LoadingButton';
import MainCard from 'components/MainCard';
import { useEffect, useState } from 'react';
import ApiService from 'service/ApiService';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

const UnassignedItem = ({ suggestiveGroups, unassignedItemsList, onSave }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [checkedItems, setCheckedItems] = useState([]);
    const [error, setError] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        let cloneUnassignedItems = _.cloneDeep(unassignedItemsList);
        setItemList(cloneUnassignedItems);
    }, [unassignedItemsList]);

    useEffect(() => {
        if (!_.isEmpty(selectedGroup) || !_.isEmpty(checkedItems)) {
            setError(false);
        }
    }, [selectedGroup, checkedItems]);

    const handleChange = (newValue) => {
        setSelectedGroup(newValue);
    };

    const onPressCheckBox = (e, data) => {
        const updatedCheckedItems = e.target.checked
            ? [...checkedItems, data.posItemId]
            : checkedItems.filter((id) => id !== data.posItemId);

        setCheckedItems(updatedCheckedItems);
    };

    const handleClick = async () => {
        if (_.isEmpty(selectedGroup) || _.isEmpty(checkedItems)) {
            setError(true);
            return;
        }
        setIsSubmitting(true);
        const payload = {
            posItems: checkedItems,
            subMenuId: selectedGroup.subMenuId,
        };
        const { data } = await ApiService.assignedSuggestiveItemAsync(payload);
        if (data) {
            setItemList(itemList.filter((item) => !checkedItems.includes(item.posItemId)));
            setCheckedItems([]);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Item has been successfully assigned to suggestive group.',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: true,
                })
            );
            onSave(true);
            setSelectedGroup(null);
        }
        setIsSubmitting(false);
    };

    const handleSelectAll = (e) => {
        let newArr = [];
        e.target.checked
            ? itemList.map((item) => newArr.push(item.posItemId))
            : (newArr = []);
        setCheckedItems(newArr);
    };

    const isAllSelected = () => {
        return checkedItems.length === itemList.length && checkedItems.length > 0;
    };

    const isPartiallySelected = () => {
        return checkedItems.length > 0 && checkedItems.length < itemList.length;
    };

    const filteredItems = itemList.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Grid container spacing={2}>
            <Grid item sx={{ width: '100%' }}>
                <MainCard
                    title={
                        <OutlinedInput
                            value={searchTerm}
                            fullWidth
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={`Search ${filteredItems.length}  items...`}
                            id="search-items"
                            startAdornment={<SearchOutlined />}
                        />
                    }
                >
                    {filteredItems && filteredItems.length > 0 ? (<Stack ml={1} mt={-1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="large"
                                    checked={isAllSelected()}
                                    indeterminate={isPartiallySelected()}
                                    onChange={(event) => handleSelectAll(event)}
                                />
                            }
                            label={<Typography variant="h5">Select All</Typography>}
                        />
                    </Stack>) : null}
                    {filteredItems && filteredItems.length > 0 ? (
                        <Stack height="52vh" overflow="auto">
                            <Stack overflow="auto" pl={1}>
                                {filteredItems.map((groupItem, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                size="large"
                                                checked={checkedItems.includes(groupItem.posItemId)}
                                                onChange={(event) => onPressCheckBox(event, groupItem)}
                                            />
                                        }
                                        label={groupItem.name}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack textAlign="center" height="68vh" justifyContent="center">
                            <Typography variant="subtitle1" color="primary">
                                No Records Found
                            </Typography>
                        </Stack>
                    )}
                    {filteredItems && filteredItems.length > 0 && (
                        <Stack overflow="auto" pl={1}>
                            <Stack direction="column" mt={2}>
                                <CardHeader
                                    title="Assign Items to Suggestive Group"
                                    sx={{ padding: 0, mb: 1.5 }}
                                />
                                <Autocomplete
                                    id="screenGroup"
                                    sx={{ width: '100%' }}
                                    options={suggestiveGroups}
                                    value={selectedGroup}
                                    getOptionLabel={(screenGroup) => screenGroup.name || ''}
                                    onChange={(event, newValue) => handleChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Select Group"
                                            error={error}
                                        />
                                    )}
                                />
                                <FormHelperText error={error} sx={{ marginLeft: 0 }}>
                                    {error && 'Please select the required field'}
                                </FormHelperText>
                            </Stack>
                            <Stack spacing={2} direction="column" mt={2}>
                                <Tooltip title="Assign">
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        onClick={handleClick}
                                    >
                                        Assign
                                    </LoadingButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    )}
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default UnassignedItem;
